body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.coverwrapper{
  position: relative;
}

.cover{
  height: 100vh;
  background-image: url(./images/logo.PNG);
  background-repeat:no-repeat;
  background-position: center;
  background-size: 50vh;
  margin-top: -100vh;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, a, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, nav, section, firstSection, credits, menu, time, mark, audio, video, canvas {
  margin: 0px;
  padding: 0px;
  border: 0px;
}

.section .contentcontainer{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-flow: wrap;
  align-items: center;
  padding: 150px
}

@media screen and (max-width: 1200px){
  .section .left{
    width: 40%;
  }
  .section .right{
    width: 40%;
  }

  .section .right img{
    width: 50%;
  }
}

@media screen and (max-width: 600px){
  .section .left{
    width: 50%;
  }
  .section .right{
    width: 50%;
  }

  .section .right img{
    width: 50%;
  }

  .cover{
    height: 100vh;
    background-image: url(./images/logo.PNG);
    background-repeat:no-repeat;
    background-position: center;
    background-size: 30vh;
    margin-top: -100vh;
  }

}

.section{
  margin-top: 100px;
  height: 400px;
}
.section .left{
  width: 40%;
}

.section .right{

  width: 40%;
  text-align: center;
}

.right img{
  width: 500px;
  max-height: 300px
}

.hide{
  display: none;
}

.titlecontent{
  font-size: 50px;
  text-align: left;
  color: black;
}

.textcontent{
  font-size: 25px;
  text-align: left;
  color: var(--inlocal);
}

.myButton input{
    display: inline-block;
    border-radius: 40px;
    color:white;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
    background-color: var(--inlocal);
}

.myButton input:hover{
  background-color: white;
  color: var(--inlocal);
}

/* Mouse Scroll CSS */

*, *:before, *:after {
  -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
 }


.mouse_scroll {
	position:absolute;
	width: 24px;
	height: 100px;
  bottom:0px;
  right:25%;
  left:50%;
  margin-left:-10px;
}


.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid rgb(0, 0, 0);
  border-bottom: 2px solid rgb(0, 0, 0);
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

.mouse {
  background: rgb(0, 0, 0);
  border-radius: 3px;
  width: 25px;
  height: 20px;
  position: relative;
}


.mouse:before {
  content: "";
  display: block;
  position: absolute;
  border:3px solid rgb(0, 0, 0);
  top: -14px;
  left: 5.1px;
  width: 15px;
  height: 27px;
  border-radius: 35px 35px 0 0;
}


@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}

/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  overflow: hidden;
  text-align: center;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: none;
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 20px;
  letter-spacing: 6px;
  font-weight: 100;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: var(--inlocal);
  color: white;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: var(--inlocal);
  color: white;
}

:root{
  --inlocal: #0171BC;
}

.cta-wrapper{
  padding-left: 250px;
  padding-right: 250px;
  padding-top: 100px;
  height: 400px;
}

.cta-wrapper > * {
  padding: 10px;
}

.cta-title{
  font-size: 50px;
}

.cta-text{
  font-size: 25px;
  color: var(--inlocal);
}


#tsparticles{
  height: 100vh;
}